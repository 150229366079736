import React, { createContext, useState } from 'react';

export const BookingContext = createContext();

export const BookingProvider = ({ children }) => {
  const [bookingData, setBookingData] = useState({
    startDate: '',
    endDate: '',
    rentDuration: 0,
    pickupTime: '',
    returnTime: '',
    totalPrice: 0,
    selectedScooters: [],
    availableSingleScooters: 0,
    availableDoubleScooters: 0,
    personalDetails: {
      firstName: '',
      lastName: '',
      hotel: '',
      phone: '',
      email: '',
    },
  });

  return (
    <BookingContext.Provider value={{ bookingData, setBookingData }}>
      {children}
    </BookingContext.Provider>
  );
};
