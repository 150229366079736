import React from "react";
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Link, Button } from "@nextui-org/react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../assets/logo.png';

const NavigationMenu = ({ isMenuOpen, setIsMenuOpen }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleMenuOpenChange = (isOpen) => {
    setIsMenuOpen(isOpen);
  };

  const handleChooseDate = () => {
    navigate("/search");
  };

  const handleGoHome = () => {
    navigate("/");
  };

  const handleNavigation = (path) => {
    navigate(path);
    setIsMenuOpen(false);
  };

  return (
    <Navbar isBordered isMenuOpen={isMenuOpen} onMenuOpenChange={handleMenuOpenChange}>
      <NavbarContent>
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="sm:hidden"
        />
        <NavbarBrand>
          <img onClick={handleGoHome} src={logo} alt="Logo" style={{ height: '48px' }} />
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent className="hidden sm:flex gap-4" justify="center">
        <NavbarItem>
          <Link color="foreground" style={{ cursor: "pointer", }} onPress={handleChooseDate}>
            {t("single_scooter")}
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link color="foreground" style={{ cursor: "pointer", }} onPress={handleChooseDate}>
            {t("double_scooter")}
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link color="foreground" style={{ cursor: "pointer", }} href="/about">
            {t("about_us")}
          </Link>
        </NavbarItem>
      </NavbarContent>
      <NavbarContent justify="end">
        <NavbarItem>
          <Button as={Link} onPress={handleChooseDate} color="primary" href="#" variant="flat">
            {t("choose_date")}
          </Button>
        </NavbarItem>
      </NavbarContent>
      <NavbarMenu>
        <NavbarMenuItem>
          <Link
            color="foreground"
            className="w-full"
            href="#"
            onPress={handleChooseDate}
            size="lg"
          >
            {t("single_scooter")}
          </Link>
        </NavbarMenuItem>
        <NavbarMenuItem>
          <Link
            color="foreground"
            className="w-full"
            href="#"
            onPress={handleChooseDate}
            size="lg"
          >
            {t("double_scooter")}
          </Link>
        </NavbarMenuItem>
        <NavbarMenuItem>
          <Link
            color="foreground"
            className="w-full"
            onPress={() => handleNavigation('/about')}
            size="lg"
          >
            {t("about_us")}
          </Link>
        </NavbarMenuItem>
      </NavbarMenu>
    </Navbar>
  );
};

export default NavigationMenu;
