import React from "react";
import { Card, CardBody, CardFooter, Image } from "@nextui-org/react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import single_image from '../assets/single_img.jpg';
import double_image from '../assets/double_img.jpg';

const ScooterCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleChooseDate = () => {
    navigate("/search");
  };

  const scooterList = [
    {
      title: t("single_scooter"),
      img: single_image, 
      price: t("scooter_sigle_from_price"),
    },
    {
      title: t("double_scooter"),
      img: double_image, 
      price: t("scooter_double_from_price"),
    },
  ];


  return (
    <div className="gap-2 grid grid-cols-1 sm:grid-cols-2">
      {scooterList.map((item, index) => (
        <Card shadow="sm" key={index} isPressable onPress={(handleChooseDate)} >
          <CardBody className="overflow-visible p-0">
            <Image
              shadow="sm"
              radius="lg"
              width="100%"
              alt={item.title}
              className="w-full object-cover h-[200px]"
              src={item.img}
            />
          </CardBody>
          <CardFooter className="text-small justify-between" style={{ textAlign: 'left', }}>
            <b>{item.title}</b>
            <p className="text-default-500">{item.price}</p>
          </CardFooter>
        </Card>
      ))}
    </div>
  );
};

export default ScooterCard;
