import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import RentalDateRangePicker from '../components/DateRange';
import TimeSelect from '../components/TimeSelect';
import { Button, Spacer } from '@nextui-org/react';
import { BookingContext } from '../context/BookingContext';

const adjustToCanaryTimezone = (date) => {
    const adjustedDate = new Date(Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        0, 0, 0
    ));
    return adjustedDate;
};

const calculateRentDuration = (startDate, endDate, pickupTime, returnTime) => {
    if (!(startDate instanceof Date) || !(endDate instanceof Date)) {
        return { duration: 0, extraDay: false };
    }

    const oneDay = 24 * 60 * 60 * 1000;
    let durationDays = Math.floor((endDate - startDate) / oneDay);

    if (durationDays < 0) durationDays = 0;

    const [pickupHours, pickupMinutes] = pickupTime.split(':').map(Number);
    const [returnHours, returnMinutes] = returnTime.split(':').map(Number);

    const pickupTotalMinutes = pickupHours * 60 + pickupMinutes;
    const returnTotalMinutes = returnHours * 60 + returnMinutes;

    let extraDay = false;
    const diffInMinutes = returnTotalMinutes - pickupTotalMinutes;

    if (diffInMinutes >= 30 || (durationDays === 0 && diffInMinutes >= 0)) {
        durationDays += 1;
        extraDay = true;
    }

    return { duration: durationDays, extraDay };
};

const BookingForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { setBookingData, bookingData } = useContext(BookingContext);
    const [dates, setDates] = useState({ startDate: bookingData.startDate || null, endDate: bookingData.endDate || null });
    const [pickupTime, setPickupTime] = useState(bookingData.pickupTime || '');
    const [returnTime, setReturnTime] = useState(bookingData.returnTime || '');
    const [rentDuration, setRentDuration] = useState(bookingData.rentDuration || null);
    const [extraDayMessage, setExtraDayMessage] = useState(false);
    const [isDateInvalid, setIsDateInvalid] = useState(false);
    const [isPickupTimeInvalid, setIsPickupTimeInvalid] = useState(false);
    const [isReturnTimeInvalid, setIsReturnTimeInvalid] = useState(false);
    const [dateErrorMessage, setDateErrorMessage] = useState('');
    const [pickupTimeErrorMessage, setPickupTimeErrorMessage] = useState('');
    const [returnTimeErrorMessage, setReturnTimeErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const updateRentDuration = useCallback(() => {
        const { startDate, endDate } = dates;

        if (startDate && endDate && pickupTime && returnTime) {
            const { duration, extraDay } = calculateRentDuration(new Date(startDate), new Date(endDate), pickupTime, returnTime);
            setRentDuration(duration);
            setBookingData(prevData => ({
                ...prevData,
                rentDuration: duration,
            }));
            if (!(startDate.toDateString() === endDate.toDateString() && duration === 1)) {
                setExtraDayMessage(extraDay);
            } else {
                setExtraDayMessage(false);
            }
        } else {
            setRentDuration(null);
            setExtraDayMessage(false);
        }
    }, [dates, pickupTime, returnTime, setBookingData]);

    useEffect(() => {
        updateRentDuration();
    }, [dates, pickupTime, returnTime, updateRentDuration]);

    const handleDateChange = (ranges) => {
        const startDate = adjustToCanaryTimezone(new Date(ranges.start.toDate()));
        const endDate = adjustToCanaryTimezone(new Date(ranges.end.toDate()));

        setDates({
            startDate,
            endDate
        });
        setBookingData(prevData => ({
            ...prevData,
            startDate,
            endDate,
        }));
        setIsDateInvalid(false);
        setDateErrorMessage('');
    };

    const handlePickupTimeChange = (e) => {
        const time = e.target.value;
        setPickupTime(time);
        setBookingData(prevData => ({
            ...prevData,
            pickupTime: time,
        }));
        setIsPickupTimeInvalid(false);
        setPickupTimeErrorMessage('');
    };

    const handleReturnTimeChange = (e) => {
        const time = e.target.value;
        setReturnTime(time);
        setBookingData(prevData => ({
            ...prevData,
            returnTime: time,
        }));
        setIsReturnTimeInvalid(false);
        setReturnTimeErrorMessage('');
    };

    const checkAvailability = async () => {
        try {
            const response = await fetch('https://hotticketsserver-production.up.railway.app/api/check-availability', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ startDate: dates.startDate, endDate: dates.endDate })
            });

            const data = await response.json();
            setBookingData(prevData => ({
                ...prevData,
                availableSingleScooters: data.availableSingleScooters,
                availableDoubleScooters: data.availableDoubleScooters,
            }));

            if (data.availableSingleScooters === 0 && data.availableDoubleScooters === 0) {
                return false;
            }
            return true;
        } catch (error) {
            console.error('Error checking availability:', error);
            return false;
        }
    };

    const handleBookNow = async () => {
        let dateError = '';
        let pickupError = '';
        let returnError = '';

        if (!dates.startDate || !dates.endDate) {
            setIsDateInvalid(true);
            dateError = t('date_error_message');
        }
        if (!pickupTime) {
            setIsPickupTimeInvalid(true);
            pickupError = t('pickup_time_error_message');
        }
        if (!returnTime) {
            setIsReturnTimeInvalid(true);
            returnError = t('return_time_error_message');
        }

        setDateErrorMessage(dateError);
        setPickupTimeErrorMessage(pickupError);
        setReturnTimeErrorMessage(returnError);

        if (dates.startDate && dates.endDate && pickupTime && returnTime) {
            setIsLoading(true);
            const isAvailable = await checkAvailability();

            setIsLoading(false);

            if (isAvailable) {
                navigate('/booking');
            } else {
                alert('No scooters available for the selected dates.');
                setDates({ startDate: null, endDate: null });
                setPickupTime('');
                setReturnTime('');
                setRentDuration(null);
            }
        }
    };

    return (
        <div>
            <div style={{ marginBottom: '0.5rem' }}>
              
                <RentalDateRangePicker 
                    label="Stay duration"
                    onChange={handleDateChange}
                    defaultValue={{
                      start: dates.startDate || undefined,
                      end: dates.endDate || undefined,
                  }}                  
                    isInvalid={isDateInvalid}
                    errorMessage={dateErrorMessage} 
                />
            </div>
            <div className="flex flex-row gap-2">
                <TimeSelect label={t('pickup_time')} onChange={handlePickupTimeChange} selectedKey={pickupTime} isInvalid={isPickupTimeInvalid} errorMessage={pickupTimeErrorMessage} />
                <TimeSelect label={t('return_time')} onChange={handleReturnTimeChange} selectedKey={returnTime} isInvalid={isReturnTimeInvalid} errorMessage={returnTimeErrorMessage} />
            </div>
            <Spacer y={3} />
            <Button color="primary" size="lg" fullWidth={true} onPress={handleBookNow} isLoading={isLoading}>
                {isLoading ? t('checking_availability') : (rentDuration !== null ? t('book_for', { count: rentDuration }) : t('book_now'))}
            </Button>
            {extraDayMessage && rentDuration > 1 && (
                <div style={{ marginTop: '0.75rem', color: '#52525B', textAlign: 'center', fontSize: 'small', }}>
                    {t('extra_day_message')}
                </div>
            )}
        </div>
    );
};

export default BookingForm;
