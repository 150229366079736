import React from "react";
import { Select, SelectItem } from "@nextui-org/react";
import { useTranslation } from "react-i18next";

const TimeSelect = ({ label, onChange, selectedKey, isInvalid, errorMessage }) => {
  const { t } = useTranslation();
  const times = [
    "09:30", "10:00", "10:30", "11:00", "11:30", "12:00",
    "12:30", "13:00", "13:30", "14:00", "14:30", "15:00",
    "15:30", "16:00", "16:30", "17:00"
  ];

  const handleSelectionChange = (e) => {
    console.log("TimeSelect change event:", e);
    console.log("Selected time:", Array.from(e.target.value).join(", "));
    onChange(e);
  };

  return (
    <div className="flex w-full max-w-xs flex-col gap-2">
      <Select
        isRequired
        isInvalid={isInvalid}
        label={label}
        placeholder={t("select_placeholder")}
        selectedKeys={new Set([selectedKey])}
        className="max-w-xs"
        onChange={handleSelectionChange}
        errorMessage={errorMessage}
      >
        {times.map((time) => (
          <SelectItem key={time} value={time}>
            {time}
          </SelectItem>
        ))}
      </Select>
    </div>
  );
};

export default TimeSelect;
