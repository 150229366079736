import React from 'react';
import { Spacer, Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BookingForm from '../components/BookingForm';
import logo from '../assets/logo.png';

const SearchPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleGoHome = () => {
    navigate("/");
  };
  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
      <div style={{ display: 'flex', justifyContent: 'center', }} >
        <img onClick={handleGoHome} style={{ height: '48px', cursor: "pointer", }} alt="HotTickets" src={logo}></img>
      </div>
      <Spacer y={8} />
      <Breadcrumbs variant="light">
        <BreadcrumbItem onPress={handleGoHome}>{t("homepage")}</BreadcrumbItem>
        <BreadcrumbItem href="#">{t("choose_date")}</BreadcrumbItem>
      </Breadcrumbs>
      <Spacer y={2} />
      <h1 style={{ fontSize: '20px', fontWeight: '600', }}>{t("choose_date_heading")}</h1>
      <Spacer y={6} />
      <BookingForm />
    </div>
  );
};

export default SearchPage;
