import React, { useState } from 'react';
import { ReactComponent as MinusIcon } from '../assets/minus_icon.svg';
import { ReactComponent as PlusIcon } from '../assets/plus_icon.svg';

const Counter = ({ onCountChange, initialCount = 1, maxCount }) => {
  const [count, setCount] = useState(initialCount);

  const handleIncrement = () => {
    if (count < maxCount) {
      const newCount = count + 1;
      setCount(newCount);
      onCountChange(newCount); // Вызов onCountChange внутри обработчика
    }
  };

  const handleDecrement = () => {
    const newCount = count > 1 ? count - 1 : 0; // Минимальное значение 0
    setCount(newCount);
    onCountChange(newCount); // Вызов onCountChange внутри обработчика
  };

  return (
    <div style={{ display: 'inline-block' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#F5F4F2', width: '120px', height: '32px', borderRadius: '8px', padding: '0px 12px' }}>
        <button onClick={handleDecrement} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
          <MinusIcon style={{ width: '24px', height: '24px' }} />
        </button>
        <span style={{ margin: '0 16px', fontSize: '16px', fontWeight: 600 }}>{count}</span>
        <button onClick={handleIncrement} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
          <PlusIcon style={{ width: '24px', height: '24px' }} />
        </button>
      </div>
    </div>
  );
};

export default Counter;
