import React from "react";
import { Tabs, Tab, Button, Spacer } from "@nextui-org/react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TablePriceSingle from "./TablePriceSingle";
import TablePriceDouble from "./TablePriceDouble";

const Price = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleChooseDate = () => {
      navigate("/search");
    };
  return (
    <div className="flex w-full flex-col">
        <div style={{ fontSize: "20px", fontWeight: "600", marginTop: "32px", }}>{t("tariff_name")}</div>
        <Spacer y={3} />
      <Tabs aria-label="Scooter Price Options" size="md">
        <Tab key="single" title={t("single_one")}>
          <TablePriceSingle/>
        </Tab>
        <Tab key="double" title={t("double_one")}>
        <TablePriceDouble/>
        </Tab>
      </Tabs>
      <Button color="primary" onPress={handleChooseDate} size="md">{t("choose_date")}</Button>
    </div>  
  );
};

export default Price;
