import React from "react";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from "@nextui-org/react";
import { useTranslation } from 'react-i18next';

const TablePriceDouble = () => {
  const { t } = useTranslation();
  return (
    <Table aria-label="Price Table">
      <TableHeader>
        <TableColumn>{t("duration")}</TableColumn>
        <TableColumn>{t("price_name")}</TableColumn>
      </TableHeader>
      <TableBody>
        <TableRow key="1">
          <TableCell>1 {t("day_single")}</TableCell>
          <TableCell>50€</TableCell>
        </TableRow>
        <TableRow key="2">
          <TableCell>2 {t("day_plural")}</TableCell>
          <TableCell>80€</TableCell>
        </TableRow>
        <TableRow key="3">
          <TableCell>3 {t("day_plural")}</TableCell>
          <TableCell>90€</TableCell>
        </TableRow>
        <TableRow key="4">
          <TableCell>4 {t("day_plural")}</TableCell>
          <TableCell>110€</TableCell>
        </TableRow>
        <TableRow key="5">
          <TableCell>5 {t("day_plural")}</TableCell>
          <TableCell>120€</TableCell>
        </TableRow>
        <TableRow key="6">
          <TableCell>6 {t("day_plural")}</TableCell>
          <TableCell>130€</TableCell>
        </TableRow>
        <TableRow key="7">
          <TableCell>7 {t("day_plural")}</TableCell>
          <TableCell>140€</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default TablePriceDouble;
