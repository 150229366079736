import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Spacer, Breadcrumbs, BreadcrumbItem, Divider, Button } from "@nextui-org/react";
import { enUS, es, ru, de, fr, nl, is } from 'date-fns/locale';
import { BookingContext } from '../context/BookingContext';
import logo from '../assets/logo.png';
import singleScooterImg from '../assets/single_white.jpg';
import doubleScooterImg from '../assets/double_white.jpg';
import { ReactComponent as EditIcon } from '../assets/edit_icon.svg';
import ScooterCheckbox from '../components/ScooterCheckbox';

const locales = {
  en: enUS,
  es: es,
  ru: ru,
  de: de,
  fr: fr,
  nl: nl,
  is: is,
};

const tariffs = {
  single: [25, 40, 45, 55, 60, 65, 70],
  double: [50, 80, 90, 110, 120, 130, 140],
};

const formatDate = (date, locale) => {
  return format(date, 'd MMM', { locale });
};

const calculatePrice = (duration, type) => {
  if (duration >= 1 && duration <= 7) {
    return tariffs[type][duration - 1];
  } else if (duration > 7) {
    const extraDays = duration - 7;
    const extraCharge = type === 'single' ? 10 : 20;
    return tariffs[type][6] + (extraDays * extraCharge);
  }
  return 0;
};

const Booking = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { bookingData, setBookingData } = useContext(BookingContext);
  const userLocale = locales[i18n.language] || locales.en;

  const [selectedScooters, setSelectedScooters] = useState([]);
  const [scooterCounts, setScooterCounts] = useState({ single: 1, double: 1 });

  const { startDate, endDate, rentDuration, pickupTime, returnTime, availableSingleScooters, availableDoubleScooters } = bookingData;

  useEffect(() => {
    if (!startDate || !endDate || !rentDuration) {
      navigate("/search");
    }
  }, [navigate, startDate, endDate, rentDuration]);

  const handleGoDates = () => {
    navigate("/search");
  };

  const handleGoHome = () => {
    navigate("/");
  };

  const handleEditDates = () => {
    navigate("/search", {
      state: { startDate, endDate, pickupTime, returnTime }
    });
  };

  const handleScooterChange = (scooter) => {
    setSelectedScooters((prevSelected) =>
      prevSelected.includes(scooter)
        ? prevSelected.filter((item) => item !== scooter)
        : [...prevSelected, scooter]
    );
    if (selectedScooters.includes(scooter)) {
      setScooterCounts((prevCounts) => ({
        ...prevCounts,
        [scooter]: 1,
      }));
    }
  };

  const handleCountChange = useCallback((scooter, count) => {
    setScooterCounts((prevCounts) => ({
      ...prevCounts,
      [scooter]: count,
    }));
  }, []);

  const formattedStartDate = startDate ? formatDate(new Date(startDate), userLocale) : '';
  const formattedEndDate = endDate ? formatDate(new Date(endDate), userLocale) : '';
  const singlePrice = calculatePrice(rentDuration, 'single') * scooterCounts.single;
  const doublePrice = calculatePrice(rentDuration, 'double') * scooterCounts.double;

  const totalPrice = (selectedScooters.includes('single') ? singlePrice : 0) + (selectedScooters.includes('double') ? doublePrice : 0);

  useEffect(() => {
    setBookingData((prevData) => ({
      ...prevData,
      totalPrice,
      selectedScooters: selectedScooters.map(scooter => ({
        type: scooter,
        count: scooterCounts[scooter],
      })),
    }));
  }, [totalPrice, selectedScooters, scooterCounts, setBookingData]);

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }} >
        <img onClick={handleGoHome} style={{ height: '48px', cursor: "pointer", }} alt="HotTickets" src={logo}></img>
      </div>
      <Spacer y={8} />
      <Breadcrumbs variant="light">
        <BreadcrumbItem onPress={handleGoDates}>{t("choose_date")}</BreadcrumbItem>
        <BreadcrumbItem href="#">{t("choose_scooter")}</BreadcrumbItem>
      </Breadcrumbs>
      <Spacer y={4} />
      <div style={{ display: "inline-block" }}>
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "9999px",
          border: "1px solid #DDDDDD",
          padding: "8px 8px 8px 16px",
          boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05)"
        }}>
          <div className="flex h-5 items-center space-x-3 text-small">
            <p style={{ fontSize: "14px", fontWeight: "500" }}>{formattedStartDate} — {formattedEndDate}</p>
            <Divider orientation="vertical" />
            <p style={{ fontSize: "14px", fontWeight: "500" }}>{rentDuration} {rentDuration > 1 ? t('day_plural') : t('day_single')}</p>
          </div>
          <Spacer x={6} />
          <Button isIconOnly color='primary' size='sm' radius='full' aria-label={t("choose_date")} onPress={handleEditDates}>
            <EditIcon />
          </Button>
        </div>
      </div>
      <Spacer y={4} />
      <h1 style={{ fontSize: '20px', fontWeight: '600' }}>{t("choose_scooter_heading")}</h1>
      {availableSingleScooters > 0 && (
        <ScooterCheckbox
          img={singleScooterImg}
          title={t('single_scooter')}
          price={calculatePrice(rentDuration, 'single')}
          seats="1"
          isChecked={selectedScooters.includes('single')}
          onChange={() => handleScooterChange('single')}
          isAdditional={selectedScooters.length > 0 && !selectedScooters.includes('single')}
          rentDuration={rentDuration}
          onCountChange={(count) => handleCountChange('single', count)}
          maxCount={availableSingleScooters}
        />
      )}
      {availableDoubleScooters > 0 && (
        <ScooterCheckbox
          img={doubleScooterImg}
          title={t('double_scooter')}
          price={calculatePrice(rentDuration, 'double')}
          seats="2"
          isChecked={selectedScooters.includes('double')}
          onChange={() => handleScooterChange('double')}
          isAdditional={selectedScooters.length > 0 && !selectedScooters.includes('double')}
          rentDuration={rentDuration}
          onCountChange={(count) => handleCountChange('double', count)}
          maxCount={availableDoubleScooters}
        />
      )}
      <Spacer y={4} />
      {selectedScooters.length > 0 && (
        <div className='shadow-inner' style={{ padding: '16px', backgroundColor: '#F4F4F5', borderRadius: '12px', marginBottom: '24px', }} >
          <p style={{ marginBottom: '4px', }}>{t('prepayment_online')}: 20 €</p>
          <p style={{ marginBottom: '4px', }}>{t('payment_on_pickup')}: {totalPrice - 20} €</p>
          <Spacer y={3} />
          <Divider orientation="horizontal" />
          <Spacer y={3} />
          <p style={{ fontWeight: '500', }}>{t('total_amount')}: {totalPrice} €</p>
        </div>
      )}
      <Button color='primary' size='lg' fullWidth isDisabled={selectedScooters.length === 0} onPress={() => navigate('/personal-details')}>
        {t('continue')}
      </Button>
      <Spacer y={8} />
    </div>
  );
};

export default Booking;
