import React, { useState, useEffect } from 'react';
import { useCheckbox, Chip, VisuallyHidden, tv, Spacer } from "@nextui-org/react";
import { useTranslation } from 'react-i18next';
import Counter from './Counter';
import { ReactComponent as TrashIcon } from '../assets/trash_icon.svg';
import { ReactComponent as UserIcon } from '../assets/user_icon.svg';

const checkbox = tv({
  slots: {
    base: "border-default hover:bg-default-200",
    content: "text-default-500"
  },
  variants: {
    isSelected: {
      true: {
        base: "border-primary bg-primary hover:bg-primary-500 hover:border-primary-500",
        content: "text-primary-foreground pl-1"
      }
    },
    isFocusVisible: {
      true: {
        base: "outline-none ring-2 ring-focus ring-offset-2 ring-offset-background",
      }
    }
  }
});

const ScooterCheckbox = ({ img, title, price, seats, isChecked, onChange, isAdditional, rentDuration, onCountChange, maxCount }) => {
  const { t } = useTranslation();
  const [count, setCount] = useState(1);
  const [totalPrice, setTotalPrice] = useState(price);

  const {
    isSelected,
    isFocusVisible,
    getBaseProps,
    getLabelProps,
    getInputProps,
  } = useCheckbox({
    isSelected: isChecked,
    onChange: () => {
      onChange();
      if (!isChecked) {
        setCount(1); // Сбросить счетчик до 1 при выборе
        setTotalPrice(price); // Сбросить цену до изначальной
        if (onCountChange) {
          onCountChange(1); // Сообщить об изменении
        }
      }
    }
  });

  const styles = checkbox({ isSelected, isFocusVisible });

  useEffect(() => {
    if (isChecked) {
      setTotalPrice(price * count);
    }
  }, [count, price, isChecked]);

  const handleCountChange = (newCount) => {
    if (newCount < 1) {
      onChange(); // Снять выбор, если счетчик меньше 1
      setCount(1); // Сбросить счетчик до 1
      setTotalPrice(price); // Сбросить цену до изначальной
      if (onCountChange) {
        onCountChange(1); // Сообщить об изменении
      }
    } else {
      setCount(newCount);
      setTotalPrice(price * newCount); // Обновить цену в соответствии с новым счетчиком
      if (onCountChange) {
        onCountChange(newCount); // Сообщить об изменении
      }
    }
  };

  const handleTrashClick = () => {
    onChange(); // Снять выбор
    setCount(1); // Сбросить счетчик до 1
    setTotalPrice(price); // Сбросить цену до изначальной
    if (onCountChange) {
      onCountChange(1); // Сообщить об изменении
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '12px',
        padding: '16px 16px 16px 8px',
        marginTop: '16px',
        boxShadow: isChecked ? 'inset 0 0 0 3px black' : 'inset 0 0 0 1px #DDDDDD',
      }}
    >
      <img src={img} alt={title} style={{ width: '100px', height: 'auto', marginRight: '16px' }} />
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
        <div style={{ flex: '1' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px', }}>
            <h3 style={{ fontSize: '14px', fontWeight: '500' }}>{title}</h3>
            <div style={{ display: 'flex', alignItems: 'center', }}>
              <UserIcon />
              <p style={{ fontSize: '12px', fontWeight: '500', marginLeft: '2px' }}>{seats}</p>
            </div>
          </div>
          <p style={{ marginTop: '2px', fontWeight: 600 }}>{totalPrice} €</p>
        </div>
        <Spacer y={3} />
        {isChecked ? (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Counter onCountChange={handleCountChange} initialCount={count} maxCount={maxCount} />
            <button onClick={handleTrashClick} style={{ background: 'none', border: 'none', cursor: 'pointer', marginLeft: '16px' }}>
              <TrashIcon style={{ width: '24px', height: '24px' }} />
            </button>
          </div>
        ) : (
          <label {...getBaseProps()} style={{ display: 'flex', alignItems: 'center' }}>
            <VisuallyHidden>
              <input {...getInputProps()} />
            </VisuallyHidden>
            <Chip
              classNames={{
                base: styles.base(),
                content: styles.content(),
              }}
              color="primary"
              variant="faded"
              {...getLabelProps()}
            >
              {isAdditional ? t('add_to_selection') : t('select_btn')}
            </Chip>
          </label>
        )}
      </div>
    </div>
  );
};

export default ScooterCheckbox;
