import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import BookingForm from '../components/BookingForm';
import NavigationMenu from '../components/NavigationMenu';
import ScooterCard from '../components/ScooterCard';
import Price from '../components/Price';
import OfficeLocation from '../components/OfficeLocation';
import Footer from '../components/Footer';

function HomePage() {
  const { t, i18n } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language || 'en');

  useEffect(() => {
    const storedLanguage = localStorage.getItem('i18nextLng') || 'en';
    if (storedLanguage !== i18n.language) {
      i18n.changeLanguage(storedLanguage);
    }
    setSelectedLanguage(storedLanguage);
  }, [i18n]);

  const handleLanguageChange = useCallback((key) => {
    setSelectedLanguage(key);
    i18n.changeLanguage(key);
    localStorage.setItem('i18nextLng', key);
  }, [i18n]);


  return (
    <div className="Homepage">
      <NavigationMenu 
        isMenuOpen={isMenuOpen} 
        setIsMenuOpen={setIsMenuOpen} 
      />
      <div style={{ padding: '0px 20px 0px 20px', maxWidth: '600px', margin: '0 auto' }}>
        <h1 style={{ fontSize: '20px', lineHeight: '28px', fontWeight: '600', textAlign: 'center', marginTop: '20px', marginBottom: '8px', }}>
          {t('title')}
        </h1>
        <p style={{ fontSize: '14px', lineHeight: '20px', textAlign: 'center', }}>
          {t('description')}
        </p>
      </div>
      <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
        <BookingForm />
      </div>
      <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
        <ScooterCard />
        <Price />
        <OfficeLocation />
      </div>
      <Footer selectedLanguage={selectedLanguage} onLanguageChange={handleLanguageChange} />
    </div>
  );
}

export default HomePage;
