import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { BookingProvider } from './context/BookingContext';
import HomePage from './pages/HomePage';
import SearchPage from './pages/SearchPage';
import AboutUs from './pages/About';
import Booking from './pages/Booking';
import PersonalDetails from './pages/PersonalDetails';
import Confirmation from './pages/Confirmation';
import LoginPage from './pages/LoginPage';
import AdminPanel from './pages/AdminPanel';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <React.StrictMode>
      <BookingProvider>
        <Router>
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/personal-details" element={<PersonalDetails />} />
            <Route path="/confirmation" element={<Confirmation />} />
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <AdminPanel />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </BookingProvider>
    </React.StrictMode>
  );
}

export default App;
