import React, { useEffect, useState, useMemo } from 'react';
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Spacer, Button, Chip, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, Pagination, Spinner } from '@nextui-org/react';

const AdminPanel = () => {
  const [bookings, setBookings] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const rowsPerPage = 10;

  useEffect(() => {
    const fetchBookings = async () => {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      try {
        const response = await fetch('https://hotticketsserver-production.up.railway.app/api/bookings', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (response.ok) {
          setBookings(data.reverse()); // Reverse the order to show newest bookings first
        } else {
          console.error('Failed to fetch bookings:', data.message);
        }
      } catch (error) {
        console.error('Failed to fetch bookings:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBookings();
  }, []);

  const handleShowData = (booking) => {
    setSelectedBooking(booking);
    onOpen();
  };

  const paginatedBookings = useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    return bookings.slice(start, start + rowsPerPage);
  }, [page, bookings]);

  const pages = useMemo(() => {
    return Math.ceil(bookings.length / rowsPerPage);
  }, [bookings.length, rowsPerPage]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', {
      timeZone: 'UTC',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  return (
    <div style={{ padding: '16px', maxWidth: '600px', margin: '0 auto' }}>
      <Spacer y={3} />
      <h2 style={{ fontSize: "20px", fontWeight: "600", }}>Admin Panel</h2>
      <Spacer y={5} />
      {isLoading ? (
        <Spinner />
      ) : (
        <Table aria-label="Bookings Table" css={{ height: "auto", minWidth: "100%" }}>
          <TableHeader>
            <TableColumn>Booking</TableColumn>
            <TableColumn>Prepayment</TableColumn>
            <TableColumn>Personal data</TableColumn>
          </TableHeader>
          <TableBody>
            {paginatedBookings.map((booking) => (
              <TableRow key={booking._id}>
                <TableCell>
                  <strong>Booking</strong> ({booking.personalDetails.firstName} {booking.personalDetails.lastName}) ({formatDate(booking.createdAt)})
                </TableCell>
                <TableCell>
                  <Chip color="success" size="sm">Paid</Chip>
                </TableCell>
                <TableCell>
                  <Button auto color="primary" size='sm' onPress={() => handleShowData(booking)}>Show data</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {pages > 1 && (
        <Pagination
          isCompact
          showControls
          showShadow
          color="primary"
          page={page}
          total={pages}
          onChange={(page) => setPage(page)}
        />
      )}
      {selectedBooking && (
        <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">Booking Details</ModalHeader>
                <ModalBody>
                  <p><strong>First Name:</strong> {selectedBooking.personalDetails.firstName}</p>
                  <p><strong>Last Name:</strong> {selectedBooking.personalDetails.lastName}</p>
                  <p><strong>Hotel:</strong> {selectedBooking.personalDetails.hotel}</p>
                  <p><strong>Phone:</strong> {selectedBooking.personalDetails.phone}</p>
                  <p><strong>Email:</strong> {selectedBooking.personalDetails.email}</p>
                  <p><strong>Start Date:</strong> {formatDate(selectedBooking.startDate)}</p>
                  <p><strong>End Date:</strong> {formatDate(selectedBooking.endDate)}</p>
                  <p><strong>Pickup Time:</strong> {selectedBooking.pickupTime}</p>
                  <p><strong>Return Time:</strong> {selectedBooking.returnTime}</p>
                  <p><strong>Rent Duration:</strong> {selectedBooking.rentDuration} days</p>
                  <p><strong>Selected Scooters:</strong></p>
                  <ul>
                    {selectedBooking.selectedScooters.map((scooter, index) => (
                      <li key={index}>{scooter.type}: {scooter.count}</li>
                    ))}
                  </ul>
                  <p><strong>Total Price:</strong> {selectedBooking.totalPrice} €</p>
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" variant="light" onPress={onClose}>Close</Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};

export default AdminPanel;
