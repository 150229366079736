import React, { useState, useRef } from "react";
import { Link, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Skeleton, Spacer, Image } from "@nextui-org/react";
import { useTranslation } from 'react-i18next';
import officeImage from '../assets/office.jpg';

const OfficeLocation = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [isLoaded, setIsLoaded] = useState(false);
  const { t } = useTranslation();
  const iframeRef = useRef(null);

  const handleIframeLoad = () => {
    setIsLoaded(true);
  };

  const handleOpenInGoogleMaps = () => {
    window.open("https://maps.app.goo.gl/23agbZNa6T1dFCi97", "_blank");
    onOpenChange(false);
  };

  return (
    <div>
        <div style={{ fontSize: "20px", fontWeight: "600", marginTop: "32px", }}>{t("our_office")}</div>
        <Spacer y={(3)} />
        <Image 
        src={officeImage}
        loading="lazy"
        />
        <Spacer y={(3)} />
        <p style={{ fontSize: "12px", color: "hsl(240 3.83% 46.08% / 1)", fontWeight: "500", }}>{t("hours_title")}</p>
        <Spacer y={(1)} />
        <p>{t("working_days")}</p>
        <p>{t("working_hours")}</p>
        <Spacer y={(3)} />
        <p style={{ fontSize: "12px", color: "hsl(240 3.83% 46.08% / 1)", fontWeight: "500", }}>{t("adress_title")}</p>
        <Spacer y={(1)} />
      <Link href="https://maps.app.goo.gl/23agbZNa6T1dFCi97" target="_blank" size="md">
        Hotel Flamingo Suites, Av. de España, 6, Local 4, Costa Adeje, Tenerife
      </Link>
      <Spacer y={(5)} />
      <Button onPress={onOpen} fullWidth="true">
        {t("how_get_button")}
      </Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">{t("map")}</ModalHeader>
              <ModalBody>
                <div style={{ position: 'relative', height: '450px', width: '100%' }}>
                  {!isLoaded && (
                    <Skeleton
                      className="absolute top-0 left-0 h-full w-full rounded-lg"
                      style={{ zIndex: 10 }}
                    />
                  )}
                  <iframe
                    ref={iframeRef}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3520.179882569904!2d-16.73354224231519!3d28.080055419914416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xc6a97762d75018f%3A0xaf535b23a6fca06f!2sHot%20Tickets!5e0!3m2!1ses!2ses!4v1721907414237!5m2!1ses!2ses"
                    title="Google Maps"
                    width="100%"
                    height="450"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    onLoad={handleIframeLoad}
                    style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', borderRadius: '8px', }}
                  ></iframe>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  {t("close")}
                </Button>
                <Button color="primary" onPress={handleOpenInGoogleMaps}>
                  {t("open_maps")}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default OfficeLocation;
