// src/pages/LoginPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Spacer, Card, CardHeader, CardBody } from '@nextui-org/react';
import { ReactComponent as EyeShow } from '../assets/eye_show.svg';
import { ReactComponent as EyeHidden } from '../assets/eye_hidden.svg';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const toggleVisibility = () => setIsVisible(!isVisible);

  const handleLogin = async (e) => {
    e.preventDefault();
    
    // Проверка на пустые поля
    if (!username || !password) {
      setError('Username and password are required.');
      return;
    }

    try {
      const response = await fetch('https://hotticketsserver-production.up.railway.app/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password })
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem('token', data.token);
        navigate('/admin');
      } else {
        setError(data.message);
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div style={{ maxWidth: '600px', margin: '0 auto', padding: '16px' }}>
      <Card>
        <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
          <h2 style={{ fontSize: "20px", fontWeight: "600", }}>Login</h2>
          <Spacer y={2}/>
        </CardHeader>
        <CardBody className="overflow-visible py-2">
          <form onSubmit={handleLogin}>
            <Input 
              fullWidth 
              label="Username" 
              placeholder="Enter your username"
              value={username} 
              onChange={(e) => setUsername(e.target.value)} 
            />
            <Spacer y={3} />
            <Input 
              fullWidth 
              label="Password" 
              placeholder="Enter your password"
              type={isVisible ? "text" : "password"}
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              endContent={
                <button 
                  className="focus:outline-none" 
                  type="button" 
                  onClick={toggleVisibility} 
                  aria-label="toggle password visibility"
                >
                  {isVisible ?  <EyeHidden /> : <EyeShow />  }
                </button>
              }
            />
            <Spacer y={3} />
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <Button type="submit" color='primary' size='lg' fullWidth>
              Login
            </Button>
          </form>
        </CardBody>
      </Card>
    </div>
  );
};

export default LoginPage;
