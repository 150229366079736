import React from "react";
import { Link, Spacer, Divider, Button } from "@nextui-org/react";
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { ReactComponent as WhatsAppIcon } from '../assets/whatsapp_icon.svg';

const Footer = ({ selectedLanguage, onLanguageChange }) => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <div style={{ backgroundColor: '#F4F4F5', marginTop: '32px', padding: '24px 20px 32px 20px', }}>
      <div style={{ maxWidth: '600px', margin: '0 auto' }}>
      <p style={{ fontSize: '16px', fontWeight: '600', }}>{t("footer_heading")}</p>
      <Spacer y={4} />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', }}>
        <Link size="sm" href="/search" color="foreground">{t("single_scooter")}</Link>
        <Link size="sm" href="/search" color="foreground">{t("double_scooter")}</Link>
        <Link size="sm" href="/about" color="foreground">{t("about_us")}</Link>
        <Spacer y={1} />
        <Button color="success" startContent={<WhatsAppIcon/>} radius="full" fullWidth="false" onClick={() => window.open('https://wa.me/+34680864494', '_blank')}>{t("whatsapp_btn")}</Button>
      </div>
      <Spacer y={6} />
      <Divider />
      <Spacer y={5} />
      <p className="text-small">{t("copyright")} © {currentYear} Alise&nbsp;Grike&nbsp;Y5641044D.</p>
      <Spacer y={5} />
      <Divider />
      <Spacer y={5} />
      <div style={{ display: 'flex', flexDirection: 'row', gap: '12px', flexWrap: 'wrap', }}>
        <Link showAnchorIcon href="https://hottickets.es/legal/aviso_legal.pdf" target="_blank" size="sm" color="foreground">
        {t("aviso_legal")}
        </Link>
        <Link showAnchorIcon href="https://hottickets.es/legal/privacidad.pdf" target="_blank" size="sm" color="foreground">
        {t("privacidad")}
        </Link>
        <Link showAnchorIcon href="https://hottickets.es/legal/cookies.pdf" target="_blank" size="sm" color="foreground">
        {t("cookies")}
        </Link>
        <Link showAnchorIcon href="https://hottickets.es/legal/terms_of_rent.pdf" target="_blank" size="sm" color="foreground">
        {t("terms_of_rent")}
        </Link>
      </div>
      <Spacer y={5} />
      <LanguageSwitcher selectedLanguage={selectedLanguage} onSelectionChange={onLanguageChange} />
      </div>
    </div>
  );
};

export default Footer;
