import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Spacer, Button, Input, Checkbox, Link } from "@nextui-org/react";
import { useTranslation } from 'react-i18next';
import { BookingContext } from '../context/BookingContext';
import logo from '../assets/logo.png';

const PersonalDetails = () => {
  const { t } = useTranslation();
  const { bookingData, setBookingData } = useContext(BookingContext);
  const location = useLocation();
  const [personalDetails, setPersonalDetails] = useState({
    firstName: bookingData.personalDetails.firstName || '',
    lastName: bookingData.personalDetails.lastName || '',
    hotel: bookingData.personalDetails.hotel || '',
    phone: bookingData.personalDetails.phone || '',
    email: bookingData.personalDetails.email || ''
  });
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    hotel: false,
    phone: false,
    email: false,
    terms: false
  });
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state && location.state.personalDetails) {
      setPersonalDetails(location.state.personalDetails);
    }
  }, [location.state]);

  const handleGoHome = () => {
    navigate("/");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPersonalDetails({ ...personalDetails, [name]: value });
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setErrors({ ...errors, terms: false });
  };

  const validateInputs = () => {
    const newErrors = {
      firstName: !personalDetails.firstName,
      lastName: !personalDetails.lastName,
      hotel: !personalDetails.hotel,
      phone: !personalDetails.phone,
      email: !personalDetails.email,
      terms: !isChecked
    };
    setErrors(newErrors);

    return !Object.values(newErrors).includes(true);
  };

  const handleContinue = () => {
    if (validateInputs()) {
      setBookingData({ ...bookingData, personalDetails });
      navigate('/confirmation'); // переход на страницу подтверждения
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }} >
        <img onClick={handleGoHome} style={{ height: '48px', cursor: "pointer", }} alt="HotTickets" src={logo}></img>
      </div>
      <Spacer y={6} />
      <h1 dangerouslySetInnerHTML={{
          __html: t('personal_info_heading').replace(/\n/g, '<br />')
        }} style={{ fontSize: '20px', fontWeight: '600', }}></h1>
      <Spacer y={6} />
      <Input 
        isRequired
        fullWidth
        label={t('first_name')}
        name="firstName"
        value={personalDetails.firstName}
        onChange={handleInputChange}
        isInvalid={errors.firstName}
        validationState={errors.firstName ? 'invalid' : 'valid'}
        errorMessage={errors.firstName ? t('required_field') : ''}
      />
      <Spacer y={2} />
      <Input
        isRequired
        fullWidth
        label={t('last_name')}
        name="lastName"
        value={personalDetails.lastName}
        onChange={handleInputChange}
        isInvalid={errors.lastName}
        validationState={errors.lastName ? 'invalid' : 'valid'}
        errorMessage={errors.lastName ? t('required_field') : ''}
      />
      <Spacer y={2} />
      <Input
        isRequired
        fullWidth
        label={t('hotel')}
        description={t('hotel_description')}
        name="hotel"
        value={personalDetails.hotel}
        onChange={handleInputChange}
        isInvalid={errors.hotel}
        validationState={errors.hotel ? 'invalid' : 'valid'}
        errorMessage={errors.hotel ? t('required_field') : ''}
      />
      <Spacer y={2} />
      <Input 
        isRequired
        fullWidth
        label={t('cell_number')}
        description={t('cell_number_description')}
        name="phone"
        value={personalDetails.phone}
        onChange={handleInputChange}
        isInvalid={errors.phone}
        validationState={errors.phone ? 'invalid' : 'valid'}
        errorMessage={errors.phone ? t('required_field') : ''}
      />
      <Spacer y={2} />
      <Input 
        isRequired
        fullWidth
        label={t('email')}
        description={t('email_description')}
        name="email"
        value={personalDetails.email}
        onChange={handleInputChange}
        isInvalid={errors.email}
        validationState={errors.email ? 'invalid' : 'valid'}
        errorMessage={errors.email ? t('required_field') : ''}
      />
      <Spacer y={3} />
      <Checkbox 
        isSelected={isChecked}
        onChange={handleCheckboxChange}
        isInvalid={errors.terms}
        validationState={errors.terms ? 'invalid' : 'valid'}
      >
        {t('agree_to')} <Link href="https://hottickets.es/legal/terms_of_rent.pdf" target="_blank">{t('terms_rent')}</Link>
      </Checkbox>
      <Spacer y={6} />
      <Button 
        color='primary' 
        size='lg' 
        fullWidth 
        onPress={handleContinue}
      >
        {t('continue')}
      </Button>
    </div>
  );
};

export default PersonalDetails;
