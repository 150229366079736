import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Spacer, Divider } from "@nextui-org/react";
import { BookingContext } from '../context/BookingContext';
import logo from '../assets/logo.png';

const Confirmation = () => {
  const { t } = useTranslation();
  const { bookingData } = useContext(BookingContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!bookingData.startDate || !bookingData.endDate || !bookingData.selectedScooters || bookingData.selectedScooters.length === 0) {
      navigate('/search');
    }
  }, [bookingData, navigate]);

  const handleGoHome = () => {
    navigate("/");
  };

  const handleEditPersonalDetails = () => {
    navigate('/personal-details', { state: { personalDetails: bookingData.personalDetails } });
  };

  const handleEditBookingDetails = () => {
    navigate('/search');
  };

  const handlePayment = async () => {
    setIsLoading(true);

    try {
      // Получаем актуальные данные из контекста перед отправкой
      const updatedBookingData = { ...bookingData };

      // Логируем данные перед отправкой
      console.log('Booking data before sending to server:', updatedBookingData);

      const response = await fetch('https://hotticketsserver-production.up.railway.app/api/bookings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ bookingData: updatedBookingData })
      });

      if (!response.ok) {
        throw new Error('Failed to save booking');
      }

      const { booking } = await response.json();
      const paymentUrl = `https://book.stripe.com/fZeaFI0dVgMP4WA8ww?client_reference_id=${booking._id}`;
      window.location.href = paymentUrl;
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    }
  };

  const formattedStartDate = bookingData.startDate ? new Date(bookingData.startDate).toLocaleDateString() : '';
  const formattedEndDate = bookingData.endDate ? new Date(bookingData.endDate).toLocaleDateString() : '';

  const renderScooterInfo = () => {
    return bookingData.selectedScooters.map((scooter, index) => {
      const scooterType = scooter.type === 'single' ? t('single_scooter') : t('double_scooter');
      return (
        <p key={index}>
          {t('selected_scooters', { count: scooter.count, scooterType })}
        </p>
      );
    });
  };

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }} >
        <img onClick={handleGoHome} style={{ height: '48px' }} alt="HotTickets" src={logo}></img>
      </div>
      <Spacer y={6} />
      <h1 style={{ fontSize: '20px', fontWeight: '600', }}>{t('confirmation_heading')}</h1>
      <Spacer y={4} />
      <div className='shadow-inner' style={{ padding: '16px', backgroundColor: '#F4F4F5', borderRadius: '16px', }}>
        <p style={{ fontSize: '12px', textTransform: 'uppercase', fontWeight: '600', color: '#3F3F46', }}>{t('personal_data')}</p>
        <Spacer y={2} />
        <p>{t('first_name')}: <span style={{ fontWeight: '500', }}>{bookingData.personalDetails.firstName}</span></p>
        <p>{t('last_name')}: <span style={{ fontWeight: '500', }}>{bookingData.personalDetails.lastName}</span></p>
        <p>{t('hotel')}: <span style={{ fontWeight: '500', }}>{bookingData.personalDetails.hotel}</span></p>
        <p>{t('cell_number')}: <span style={{ fontWeight: '500', }}>{bookingData.personalDetails.phone}</span></p>
        <p>{t('email')}: <span style={{ fontWeight: '500', }}>{bookingData.personalDetails.email}</span></p>
        <Spacer y={3} />
        <Button fullWidth variant='flat' color='primary' size='sm' onPress={handleEditPersonalDetails}>{t('edit')}</Button>
      </div>
      <Spacer y={2} />
      <div className='shadow-inner' style={{ padding: '16px', backgroundColor: '#F4F4F5', borderRadius: '16px', }}>
        <p style={{ fontSize: '12px', textTransform: 'uppercase', fontWeight: '600', color: '#3F3F46', }}>{t('booking_info')}</p>
        <Spacer y={2} />
        {renderScooterInfo()}
        <p>{t('start_date')}: <span style={{ fontWeight: '500', }}>{formattedStartDate}</span></p>
        <p>{t('end_date')}: <span style={{ fontWeight: '500', }}>{formattedEndDate}</span></p>
        <p>{t('pickup_time')}: <span style={{ fontWeight: '500', }}>{bookingData.pickupTime}</span></p>
        <p>{t('return_time')}: <span style={{ fontWeight: '500', }}>{bookingData.returnTime}</span></p>
        <p>{t('return_duration')}: <span style={{ fontWeight: '500', }}>{bookingData.rentDuration}</span></p>
        <Spacer y={3} />
        <Button fullWidth variant='flat' color='primary' size='sm' onPress={handleEditBookingDetails}>{t('edit')}</Button>
      </div>
      <Spacer y={2} />
      <div className='shadow-inner' style={{ padding: '16px', backgroundColor: '#F4F4F5', borderRadius: '16px', }}>
        <p style={{ fontSize: '12px', textTransform: 'uppercase', fontWeight: '600', color: '#3F3F46', }}>{t('rental_cost')}</p>
        <Spacer y={2} />
        <p>{t('prepayment_now')}: <span style={{ fontWeight: '500', }}>20 €</span></p>
        <p>{t('payment_on_pickup')}: <span style={{ fontWeight: '500', }}>{bookingData.totalPrice - 20} €</span></p>
        <p>{t('deposit_on_pickup')}: <span style={{ fontWeight: '500', }}>100 €</span></p>
        <Spacer y={4} />
        <Divider />
        <Spacer y={2} />
        <p style={{ fontWeight: '500', textTransform: 'uppercase', }}>{t('total_amount')}: {bookingData.totalPrice} €</p>
      </div>
      <Spacer y={6} />
      <Button size='lg' fullWidth color='primary' onPress={handlePayment} isLoading={isLoading}>{t('pay_btn')}</Button>
      <Spacer y={6} />
    </div>
  );
};

export default Confirmation;
