import React from "react";
import { useTranslation } from 'react-i18next';
import { Select, SelectItem, Avatar } from "@nextui-org/react";

const languages = [
  { key: "en", name: "English", flag: "https://flagcdn.com/us.svg" },
  { key: "es", name: "Español", flag: "https://flagcdn.com/es.svg" },
  { key: "ru", name: "Русский", flag: "https://flagcdn.com/ru.svg" },
  { key: "de", name: "Deutsch", flag: "https://flagcdn.com/de.svg" },
  { key: "nl-BE", name: "Belgian", flag: "https://flagcdn.com/be.svg" },
  { key: "nl", name: "Nederlands", flag: "https://flagcdn.com/nl.svg" },
  { key: "fr", name: "Français", flag: "https://flagcdn.com/fr.svg" },
  { key: "is", name: "Íslenska", flag: "https://flagcdn.com/is.svg" },
];

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const selectedLanguage = i18n.language;

  const handleLanguageChange = (keys) => {
    const key = Array.from(keys).join(',');
    i18n.changeLanguage(key);
    localStorage.setItem('i18nextLng', key);
  };

  return (
    <Select
      variant="bordered"
      className="max-w-xs"
      label="Select language"
      selectedKeys={new Set([selectedLanguage])}
      onSelectionChange={handleLanguageChange}
    >
      {languages.map((language) => (
        <SelectItem
          key={language.key}
          value={language.key}
          startContent={<Avatar alt={language.name} className="w-6 h-6" src={language.flag} />}
        >
          {language.name}
        </SelectItem>
      ))}
    </Select>
  );
};

export default LanguageSwitcher;
