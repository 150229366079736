import React from 'react';
import { DateRangePicker } from '@nextui-org/react';
import { getLocalTimeZone, today } from '@internationalized/date';
import { I18nProvider } from '@react-aria/i18n';
import { useTranslation } from 'react-i18next';

const RentalDateRangePicker = ({ onChange, isInvalid, errorMessage }) => {
  const { t, i18n } = useTranslation();

  const locale = i18n.language === 'is' ? 'en' : i18n.language;

  const handleSelect = (ranges) => {
    onChange({
      start: ranges.start,
      end: ranges.end
    });
  };

  return (
    <I18nProvider locale={locale}>
      <DateRangePicker
        isRequired
        isInvalid={isInvalid}
        label={t('rental_period')}
        minValue={today(getLocalTimeZone())}
        onChange={handleSelect}
        valueFormat="dd/mm/yyyy"
        errorMessage={errorMessage}
      />
    </I18nProvider>
  );
};

export default RentalDateRangePicker;