import React, { useState, useEffect, useCallback } from 'react';
import { Spacer, Breadcrumbs, BreadcrumbItem, Button } from "@nextui-org/react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NavigationMenu from '../components/NavigationMenu';
import Footer from '../components/Footer';
import OfficeLocation from '../components/OfficeLocation';

const AboutUs = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language || 'en');
  
    useEffect(() => {
      const storedLanguage = localStorage.getItem('i18nextLng') || 'en';
      if (storedLanguage !== i18n.language) {
        i18n.changeLanguage(storedLanguage);
      }
      setSelectedLanguage(storedLanguage);
    }, [i18n]);
  
    const handleLanguageChange = useCallback((key) => {
      setSelectedLanguage(key);
      i18n.changeLanguage(key);
      localStorage.setItem('i18nextLng', key);
    }, [i18n]);

    const handleGoHome = () => {
        navigate("/");
      };

    return (
        <div className='AboutUs'>
        <NavigationMenu 
        isMenuOpen={isMenuOpen} 
        setIsMenuOpen={setIsMenuOpen} 
        />
        <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
        <Breadcrumbs variant="light">
        <BreadcrumbItem onPress={handleGoHome}>{t("homepage")}</BreadcrumbItem>
        <BreadcrumbItem href="#">{t("about_us")}</BreadcrumbItem>
        </Breadcrumbs>
        <div>
            <Spacer y={5} />
        <p style={{ fontSize: '20px', fontWeight: '600', }}>HotTickets</p>
        <Spacer y={3} />
        <p>{t("about_text")}</p>
        <Spacer y={4} />
        <Button color='primary' variant='bordered' size='lg' fullWidth="true" onClick={() => window.open('https://tinyurl.com/429pdfft', '_blank')}>
            {t("review_button")}
        </Button>
        </div>
        <OfficeLocation />
        </div>
        <Footer selectedLanguage={selectedLanguage} onLanguageChange={handleLanguageChange} />
        </div>
    );
};

export default AboutUs;